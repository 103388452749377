import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  Typography,
} from "@mui/material";
import { HelpOutline, ExpandMore } from "@mui/icons-material";
import { FC } from "react";

enum AvailableBands {
  AT = "AT",
  UT1 = "UT1",
  UT2 = "UT2",
}

type Configuration = {
  descriptor: string;
  lower: number;
  upper: number;
};

export const BANDS: Record<AvailableBands, Configuration> = {
  [AvailableBands.AT]: {
    descriptor: "Anaerobic Threshold - breathing hard",
    lower: 0.8,
    upper: 0.9,
  },
  [AvailableBands.UT1]: {
    descriptor: "Utilisation 1 - able to speak only single words",
    lower: 0.7,
    upper: 0.8,
  },
  [AvailableBands.UT2]: {
    descriptor: "Utilisation 2 - able to have a conversation",
    lower: 0.55,
    upper: 0.7,
  },
};

export type BandsData = Record<
  AvailableBands,
  {
    lower: number;
    upper: number;
  }
>;

const Bands: FC<{ bands: BandsData }> = ({ bands }) => (
  <Stack gap={1}>
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Band</TableCell>
            <TableCell>Lower</TableCell>
            <TableCell>Upper</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(bands).map((band) => {
            return (
              <TableRow key={band}>
                <TableCell>
                  <Stack direction="row" gap={1}>
                    {band} (
                    {Math.round(BANDS[band as AvailableBands].lower * 100)}-
                    {Math.round(BANDS[band as AvailableBands].upper * 100)}%)
                    <Tooltip
                      title={BANDS[band as AvailableBands].descriptor}
                      placement="right"
                      enterTouchDelay={0}
                    >
                      <HelpOutline fontSize="small" color="primary" />
                    </Tooltip>
                  </Stack>
                </TableCell>
                <TableCell>{bands[band as keyof BandsData].lower}</TableCell>
                <TableCell>{bands[band as keyof BandsData].upper}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        How are the bands calculated?
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={1}>
          <Typography>
            The HR bands exist somewhere on your Heart Rate Reserve (HRR). Your
            HRR is the difference between your resting HR and your maximum HR.
            If you should be working at 50% intensity, this means halfway along
            your HRR.
          </Typography>

          <Typography variant="h6" component="p">
            Example
          </Typography>
          <Slider
            disabled
            sx={{
              ".MuiSlider-track": {
                color: "primary.main",
              },
              ".MuiSlider-thumb": {
                color: "primary.main",
              },
            }}
            size="small"
            value={[60, 125, 190]}
            max={220}
            min={0}
            marks={[
              {
                value: 60,
                label: (
                  <Typography component="span" fontSize="small">
                    60 (resting)
                  </Typography>
                ),
              },
              {
                value: 190,
                label: (
                  <Typography component="span" fontSize="small">
                    190 bpm (max)
                  </Typography>
                ),
              },
              {
                value: 125,
                label: (
                  <Typography component="span" fontSize="small">
                    125 bpm (50%)
                  </Typography>
                ),
              },
            ]}
          />
          <Typography align="center" fontSize="small">
            <code>(max - resting) × 50% + resting</code>
          </Typography>
          <Typography align="center" fontSize="small">
            <code>(190 - 60) × 50% + 60 = 125</code>
          </Typography>
        </Stack>
      </AccordionDetails>
    </Accordion>
  </Stack>
);

export default Bands;
