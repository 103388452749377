export type Calculator = "simple" | "active" | "over-40s";

export const calculateMaxHr = (calculator: Calculator, age: number) => {
  switch (calculator) {
    case "simple":
      return 220 - age;

    case "active":
      return 211 - age * 0.64;

    case "over-40s":
      return 207 - age * 0.7;

    default:
      throw new Error("Unrecognised max HR calculator: " + calculator);
  }
};

export const calculatePercentageOfMhr = (
  percentage: number,
  restingHr: number,
  maxHr: number
) => Math.round((maxHr - restingHr) * percentage + restingHr);
