import "./App.css";
import { Container, Typography, Stack } from "@mui/material";
import Form, { FormData } from "./Form";
import { useCallback, useEffect, useState } from "react";
import Bands, { BANDS, BandsData } from "./Bands";
import { calculateMaxHr, calculatePercentageOfMhr } from "./calculator";

const STORED_FORM_VALUES_KEY = "hr-bands-form-values";
const storedFormValues = window.localStorage.getItem(STORED_FORM_VALUES_KEY);
const formDefaultValues: FormData = storedFormValues
  ? JSON.parse(storedFormValues)
  : {
      restingHr: "",
      maxHrKnown: true,
      maxHr: "",
      age: "",
      maxHrCalculator: "simple",
    };

const App = () => {
  const [bands, setBands] = useState<BandsData>();

  const onSubmit = useCallback(
    (data: FormData) => {
      const restingHr = Number(data.restingHr);
      const maxHr = Number(
        data.maxHrKnown
          ? (data.maxHr as number)
          : calculateMaxHr(data.maxHrCalculator, data.age as number)
      );

      setBands(
        (Object.keys(BANDS) as [keyof BandsData]).reduce<BandsData>(
          (acc, bandKey) => ({
            ...acc,
            [bandKey]: {
              lower: calculatePercentageOfMhr(
                BANDS[bandKey as keyof BandsData].lower,
                restingHr,
                maxHr
              ),
              upper: calculatePercentageOfMhr(
                BANDS[bandKey].upper,
                restingHr,
                maxHr
              ),
            },
          }),
          {} as BandsData
        )
      );

      window.localStorage.setItem(STORED_FORM_VALUES_KEY, JSON.stringify(data));
    },
    [setBands]
  );

  useEffect(() => {
    if (storedFormValues) {
      onSubmit(formDefaultValues);
    }
  }, [onSubmit]);

  const onReset = useCallback(() => {
    setBands(undefined);
  }, [setBands]);

  return (
    <Container maxWidth="sm">
      <Stack spacing={3} pb={3}>
        <Typography variant="h3" component="h1" pt={2}>
          Heart rate bands
        </Typography>

        <Form
          onSubmit={onSubmit}
          onReset={onReset}
          defaultValues={formDefaultValues}
        />

        {bands && <Bands bands={bands} />}
      </Stack>
    </Container>
  );
};

export default App;
