import {
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
  Stack,
  Button,
  TextField,
  Paper,
  Switch,
  Container,
} from "@mui/material";
import { FC } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { calculateMaxHr, Calculator } from "./calculator";
export interface FormData {
  restingHr: number | "";
  maxHrKnown: boolean;
  age: number | "";
  maxHr: number | "";
  maxHrCalculator: Calculator;
}

const Form: FC<{
  onSubmit: (formData: FormData) => void;
  onReset: () => void;
  defaultValues: FormData;
}> = ({
  onSubmit: triggerFormSubmission,
  onReset: triggerFormReset,
  defaultValues,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>();

  watch(() => {
    triggerFormReset();
  });

  // the default is set here as well as in the field because otherwise the
  // watcher doesn't trigger early enough and causes a flicker
  const [watchedMaxHrKnown, watchedMaxHrCalculator, watchedAge] = watch(
    ["maxHrKnown", "maxHrCalculator", "age"],
    defaultValues
  );

  const onSubmit: SubmitHandler<FormData> = (data) => {
    triggerFormSubmission(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Controller
          name="restingHr"
          control={control}
          rules={{
            required: "Resting heart rate is required",
            min: { value: 1, message: "Heart rate must be greater than 0" },
          }}
          defaultValue={defaultValues.restingHr}
          render={({ field }) => (
            <TextField
              label="Resting HR"
              type="number"
              error={!!errors.restingHr}
              helperText={errors.restingHr && errors.restingHr.message}
              InputLabelProps={{
                shrink: true,
              }}
              {...field}
            />
          )}
        />

        <Controller
          name="maxHrKnown"
          control={control}
          defaultValue={defaultValues.maxHrKnown}
          render={({ field }) => (
            <FormControlLabel
              control={<Switch checked={field.value} {...field} />}
              label="I know my maximum HR"
            />
          )}
        />

        {watchedMaxHrKnown ? (
          <Controller
            key="maxHr"
            name="maxHr"
            control={control}
            rules={{
              required: "Maximum heart rate is required",
              min: { value: 1, message: "Heart rate must be greater than 0" },
            }}
            defaultValue={defaultValues.maxHr}
            render={({ field }) => (
              <TextField
                label="Maximum HR"
                type="number"
                error={!!errors.maxHr}
                helperText={errors.maxHr && errors.maxHr.message}
                InputLabelProps={{
                  shrink: true,
                }}
                {...field}
              />
            )}
          />
        ) : (
          <>
            <Controller
              key="max-hr-method"
              name="maxHrCalculator"
              control={control}
              rules={{ required: true }}
              defaultValue={defaultValues.maxHrCalculator}
              render={({ field }) => (
                <Paper>
                  <Container fixed>
                    <Typography variant="caption">
                      How to calculate maximum HR
                    </Typography>
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value="simple"
                        control={<Radio />}
                        label="220 - age (simplest)"
                      />
                      <FormControlLabel
                        value="over-40s"
                        control={<Radio />}
                        label="207 - age x 70% (good for over 40s)"
                      />
                      <FormControlLabel
                        value="active"
                        control={<Radio />}
                        label="211 - age x 64% (good for active folks)"
                      />
                    </RadioGroup>
                  </Container>
                </Paper>
              )}
            />

            <Controller
              key="age"
              name="age"
              control={control}
              rules={{
                required: "Age is required",
                min: { value: 1, message: "Heart rate must be greater than 0" },
              }}
              defaultValue={defaultValues.age}
              render={({ field }) => (
                <TextField
                  label="Age"
                  type="number"
                  error={!!errors.age}
                  helperText={
                    errors.age
                      ? errors.age.message
                      : watchedAge && watchedMaxHrCalculator
                      ? `Calculated max HR: ${calculateMaxHr(
                          watchedMaxHrCalculator,
                          watchedAge
                        )}`
                      : ""
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
          </>
        )}

        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          type="submit"
        >
          Calculate
        </Button>
      </Stack>
    </form>
  );
};

export default Form;
